import React from 'react';
import './ChopsticksPage.css';

const ChopsticksPageFr = () => {
  return (
    <div className="chopsticks-page">
      <div className="chopsticks-content">
        <div className="chopsticks-image-section">
          <img 
            src="/aya_website_data/homepage_pics/chopsticks_product_page_cropped.jpg" 
            alt="Chopsticks Product"
            className="chopsticks-image"
          />
        </div>
        <div className="chopsticks-text-section">
          <h2 className="chopsticks-title">CHOPSTICKS</h2>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Baguettes en alliage de fibre de verre de qualité supérieure.</span> Les baguettes AYA sont fabriquées dans un matériau de qualité supérieure et très durable. Les baguettes en fibre de verre vous offrent une expérience culinaire incomparable, supérieure aux ustensiles en bois, en plastique ou en métal.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Baguettes réutilisables.</span> Les baguettes AYA font un cadeau parfait pour les amateurs de sushis et de cuisine asiatique. Nos baguettes vont au lave-vaisselle et sont conçues pour être nettoyées très facilement sans résidus d'huile, de graisse ou de nourriture.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Conception japonaise originale.</span> Les baguettes AYA sont parfaitement conçues pour une manipulation facile, même pour les débutants. La forme carrée facile à saisir et les bouts pointus antidérapants garantissent une expérience culinaire sans effort.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Classiques et élégantes.</span> Les baguettes AYA sont sculptées avec notre motif de vague japonais AYA original, inspiré d'un design classique japonais. Les baguettes AYA sont le complément parfait à votre kit de préparation de sushis AYA.
          </p>

          <p className="chopsticks-paragraph">
            Vous êtes en sécurité avec nous. Les baguettes AYA sont garanties à vie.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChopsticksPageFr;