import React from 'react';
import './ChopsticksPage.css';

const ChopsticksPageIt = () => {
  return (
    <div className="chopsticks-page">
      <div className="chopsticks-content">
        <div className="chopsticks-image-section">
          <img 
            src="/aya_website_data/homepage_pics/chopsticks_product_page_cropped.jpg" 
            alt="Chopsticks Product"
            className="chopsticks-image"
          />
        </div>
        <div className="chopsticks-text-section">
          <h2 className="chopsticks-title">CHOPSTICKS</h2>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Bacchette in fibra di vetro premium e sicure.</span> Le bacchette AYA sono realizzate in materiale di alta qualità, altamente resistente e per uso alimentare. Le bacchette in fibra di vetro ti offrono un'esperienza culinaria incomparabile, superiore agli utensili in legno, plastica o metallo.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Bacchette riutilizzabili.</span> Le bacchette AYA sono il regalo perfetto per gli amanti del sushi e del cibo asiatico. Le nostre bacchette sono lavabili in lavastoviglie e progettate per essere pulite molto facilmente senza olio, grasso o residui di cibo.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Design originale giapponese.</span> Le bacchette AYA dalla forma perfetta sono progettate per una facile manipolazione, anche per i principianti. Il design quadrato di facile presa e la punta appuntita, antiscivolo e smerigliata garantiscono un'esperienza culinaria senza sforzo.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Classico ed elegante.</span> Le bacchette AYA sono intagliate con il nostro originale motivo a onde giapponesi AYA, ispirato a un design classico giapponese. Le bacchette AYA sono il complemento perfetto per il tuo kit per la preparazione del sushi AYA.
          </p>

          <p className="chopsticks-paragraph">
            Sei al sicuro con noi. Le bacchette AYA sono garantite a vita.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChopsticksPageIt;