import React from 'react';
import './ChopsticksPage.css';

const ChopsticksPageDe = () => {
  return (
    <div className="chopsticks-page">
      <div className="chopsticks-content">
        <div className="chopsticks-image-section">
          <img 
            src="/aya_website_data/homepage_pics/chopsticks_product_page_cropped.jpg" 
            alt="Chopsticks Product"
            className="chopsticks-image"
          />
        </div>
        <div className="chopsticks-text-section">
          <h2 className="chopsticks-title">CHOPSTICKS</h2>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Hochwertige und sichere Fiberglas-Essstäbchen.</span> AYA Essstäbchen bestehen aus hochwertigem, sehr haltbarem und lebensmittelechtem Material. Fiberglas-Essstäbchen bieten Ihnen ein unvergleichliches Esserlebnis, das Holz-, Kunststoff- oder Metallutensilien überlegen ist.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Wiederverwendbare Essstäbchen.</span> AYA Essstäbchen sind das perfekte Geschenk für Sushi- und asiatische Feinschmecker. Unsere Essstäbchen sind spülmaschinenfest und so konzipiert, dass sie sehr leicht ohne Öl, Fett oder Lebensmittelrückstände gereinigt werden können.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Ursprüngliches japanisches Design.</span> Die formvollendeten AYA Essstäbchen sind auf einfache Handhabung auch für Anfänger ausgelegt. Das griffige quadratische Design und die spitze, rutschfeste, mattierte Spitze garantieren ein müheloses Esserlebnis.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Klassisch und stilvoll.</span> AYA Essstäbchen sind mit unserem original AYA japanischen Wellenmuster geschnitzt, inspiriert von einem japanischen klassischen Design. AYA Essstäbchen sind die perfekte Ergänzung zu Ihrem AYA Sushi-Set.
          </p>

          <p className="chopsticks-paragraph">
            Bei uns sind Sie sicher. AYA Essstäbchen haben eine lebenslange Garantie.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChopsticksPageDe;