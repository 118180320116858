import React, { useState, useEffect, useRef } from "react";
import "./VideoCarousel.css";

const BUCKET_BASE_URL = "https://aya-sushi-assets.s3.us-west-2.amazonaws.com/";

const VideoCarousel = ({ videos }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // For swipe functionality
  const startX = useRef(0);
  const currentX = useRef(0);
  const isSwiping = useRef(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
    currentX.current = startX.current;
    isSwiping.current = true;
  };

  const handleTouchMove = (e) => {
    if (!isSwiping.current) return;
    currentX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!isSwiping.current) return;
    isSwiping.current = false;

    const deltaX = currentX.current - startX.current;
    const threshold = 50; // The swipe distance threshold

    if (deltaX > threshold) {
      // Swiped to the right -> previous slide
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? videos.length - 1 : prevIndex - 1
      );
    } else if (deltaX < -threshold) {
      // Swiped to the left -> next slide
      setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }
  };

  if (isMobile) {
    return (
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className="carousel"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {videos.map((video, index) => (
            <div
              key={video.id}
              className={`carousel-slide ${index === currentIndex ? "active" : "inactive"}`}
            >
              <div className="video-wrapper">
                <video
                  controls
                  controlsList="nodownload"
                  preload="none"
                  src={`${BUCKET_BASE_URL}${video.src}`}
                  poster={`${BUCKET_BASE_URL}${video.thumbnail}`}
                  className="video"
                />
              </div>
            </div>
          ))}
        </div>
        {/* Pagination dots remain to show how many videos and which one is active */}
        <div className="pagination">
          {videos.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="carousel-container">
        {videos.map((video) => (
          <div key={video.id} className="video-card">
            <video
              controls
              controlsList="nodownload"
              preload="none"
              src={`${BUCKET_BASE_URL}${video.src}`}
              poster={`${BUCKET_BASE_URL}${video.thumbnail}`}
              className="video"
            />
            <div className="video-title">{video.title}</div>
          </div>
        ))}
      </div>
    );
  }
};

export default VideoCarousel;