import React from "react";
import "./PDFInstructions.css";

const BUCKET_BASE_URL = "https://aya-sushi-assets.s3.us-west-2.amazonaws.com/";

const PDFInstructions = ({ pdfs }) => {
  return (
    <div className="pdf-instructions">
      {pdfs.map((pdf, index) => (
        <div key={index} className="pdf-card">
          <a
            href={`${BUCKET_BASE_URL}${pdf.src}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="pdf-icon"></div>
          </a>
          <div className="pdf-title">{pdf.title}</div>
        </div>
      ))}
    </div>
  );
};

export default PDFInstructions;