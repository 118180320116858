import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* Logo Section */}
      <div className="footer-logo">
        <img
          src="/aya_website_data/homepage_pics/fish.png"
          alt="Aya Sushi Logo"
          className="footer-fish"
          onMouseEnter={(e) => (e.target.src = "/aya_website_data/homepage_pics/fish_smile.png")}
          onMouseLeave={(e) => (e.target.src = "/aya_website_data/homepage_pics/fish.png")}
        />
      </div>
      
      {/* Footer Details */}
      <div className="footer-details">
        {/* Support Mail */}
        <div className="footer-support">
          <a href="mailto:support@sushiaya.com" className="footer-link">
          support@sushiaya.com
          </a>
        </div>

        {/* Copyright */}
        <div className="footer-copyright">
          <p>© 2019 Sushi AYA</p>
        </div>

        {/* Privacy Policy */}
        <div className="footer-privacy">
          <a href="/privacy-policy" className="footer-link">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;