import React, { useState, useEffect } from "react";
import "./Header.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }
  }, [menuOpen]);

  return (
    <header className="header">
      {/* Social Media Icons */}
      <div className="social-icons">        
        <a href="https://www.instagram.com/aya_sushi_official/" target="_blank" rel="noopener noreferrer">
          <img src="/aya_website_data/homepage_pics/instagram_icon.avif" alt="Instagram" className="social-icon" />
        </a>
        <a href="https://www.facebook.com/sushiaya/" target="_blank" rel="noopener noreferrer">
          <img src="/aya_website_data/homepage_pics/facebook_icon.avif" alt="Facebook" className="social-icon" />
        </a>
      </div>

      {/* Logo */}
      <a href="/" className="logo-link">
        <img src="/aya_website_data/homepage_pics/sushi_aya_logo.png" alt="Aya Sushi Logo" className="logo" />
      </a>

      {/* Desktop Navigation */}
      <nav className="nav-links">
        <a href="/" className="nav-link">
          HOME
        </a>
        <a href="/support" className="nav-link">
          SUPPORT
        </a>
      </nav>

      {/* Hamburger Menu */}
      <button className="hamburger-menu" onClick={toggleMenu}>
        {menuOpen ? "✕" : "☰"} {/* Hamburger (☰) turns into X (✕) */}
      </button>

      {/* Overlay Menu */}
      {menuOpen && (
        <div className="menu-overlay" onClick={toggleMenu}>
          <nav className="mobile-nav" onClick={(e) => e.stopPropagation()}>
            <a href="/" className="nav-link">
              Home
            </a>
            <hr /> {/* Divider */}
            <a href="/support" className="nav-link">
              Support
            </a>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;