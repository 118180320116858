import React from 'react';
import './ProductCard.css';

const ProductCard = ({ image, name, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <div className="product-image" style={{ backgroundImage: `url(${image})` }}>
        <div className="product-overlay">
          <span className="product-name">{name}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;