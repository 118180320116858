import React from "react";
import "./VideoTutorials.css";
import VideoCarousel from "./VideoCarousel";

const VideoTutorials = ({ sectionTitle, videos }) => {
  return (
    <div className="video-tutorials-container">
      <h3 className="video-section-title">{sectionTitle}</h3>
      <VideoCarousel videos={videos} />
    </div>
  );
};

export default VideoTutorials;