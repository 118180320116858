import React from 'react';
import ProductCard from './ProductCard';
import './ProductLine.css';

const ProductLine = ({ title, products }) => {
  return (
    <div className="product-line">
      <h2 className={`product-line-title ${title.toLowerCase().replace(" ", "-")}`}>
        {title}
      </h2>
      <div className="product-grid">
        {products.map((product) => (
          <ProductCard
            key={product.name}
            image={product.image}
            name={product.name}
            onClick={product.onClick} // Pass the onClick handler
          />
        ))}
      </div>
    </div>
  );
};

export default ProductLine;