import React from 'react';
import ProductLine from './ProductLine';
import './ProductSection.css';

const ProductSection = () => {
  // Products data
  const classicProducts = [
    { name: 'Bazooka Kit 3', image: 'aya_website_data/homepage_pics/bazooka_kit_3.jpg', id: 'bazooka-kit-en' },
    { name: 'Sushi Maker 3', image: 'aya_website_data/homepage_pics/sushi_maker_3.jpg', id: 'sushimaker-territory' },
    { name: 'Lover Set', image: 'aya_website_data/homepage_pics/lover_set.jpg', id: 'loverset-territory' },
    { name: 'Bamboo Kit 2', image: 'aya_website_data/homepage_pics/bamboo_kit_2.jpg', id: 'bambookit2-territory' },
    { name: 'Chopsticks', image: 'aya_website_data/homepage_pics/chopsticks.jpg', id: 'chopsticks-territory' },
  ];

  const ecoProducts = [
    { name: 'Eco Kit', image: 'aya_website_data/homepage_pics/eco_kit.png', id: 'ecokit-territory' },
    { name: 'Eco Kit Max', image: 'aya_website_data/homepage_pics/eco_kit_max.jpg', id: 'ecokitmax-en' },
  ];

  // Handle product click based on QR code
  const handleProductClick = (productId) => {
    const qrSource = sessionStorage.getItem('qrCodeSource');
  
    const productsWithTerritoryPages = ['sushimaker-territory', 'loverset-territory', 'bambookit2-territory', 
      'chopsticks-territory', 'ecokit-territory'];

    if (productsWithTerritoryPages.includes(productId)) {
      // For products other than the bazooka and ecokitmax, just navigate to the territory selection page
      window.location.href = `/${productId}`;
      return; // Do not clear qrCodeSource here
    }
  
    // For the bazooka and ecokitmax, handle redirection and then clear qrCodeSource
    if (qrSource === 'videos') {
      window.location.href = `/${productId}#videos`;
    } else if (qrSource === 'pdfs') {
      window.location.href = `/${productId}#pdfs`;
    } else {
      window.location.href = `/${productId}`;
    }
  
    sessionStorage.removeItem('qrCodeSource'); // Clear for non-intermediate products
  };

  return (
    <div id="choose-your-product" className="product-section">
      <h1 className="product-section-title">CHOOSE YOUR PRODUCT</h1>
      <ProductLine
        title="AYA CLASSIC"
        products={classicProducts.map(product => ({
          ...product,
          onClick: () => handleProductClick(product.id),
        }))}
      />
      <ProductLine
        title="AYA ECO"
        products={ecoProducts.map(product => ({
          ...product,
          onClick: () => handleProductClick(product.id),
        }))}
      />
    </div>
  );
};

export default ProductSection;