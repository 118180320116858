import React, { useState, useEffect } from 'react';
import './WelcomeModal.css';
import CONFIG from '../config';

const { BASE_URL } = CONFIG;

const WelcomeModal = ({ onClose }) => {
  const [territory, setTerritory] = useState('');
  const [email, setEmail] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { email, territory };

    try {
      const response = await fetch(`${BASE_URL}send_support_email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your message has been sent successfully!");
        setTerritory('');
        setEmail('');
        onClose();
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      alert("Failed to send the message. Please try again.");
    }
  };

  return (
    <div className="welcome-modal-overlay">
      <div className={`welcome-modal-container ${visible ? 'visible' : ''}`}>
        <button className="welcome-modal-close" onClick={onClose}>✕</button>

        <img 
          src="/aya_website_data/homepage_pics/fish.png" 
          alt="Fish" 
          className="welcome-modal-fish"
        />

        <h1 className="welcome-modal-title">HELLO :)</h1>
        <p className="welcome-modal-subtitle">
          Please register to activate your AYA lifetime warranty
        </p>

        <div className="welcome-modal-input-wrapper">
          <select
            className="welcome-modal-select"
            value={territory}
            onChange={(e) => setTerritory(e.target.value)}
          >
            <option value="">Choose Your Territory</option>
            <option value="US">US</option>
            <option value="IT">IT</option>
            <option value="DE">DE</option>
            <option value="UK">UK</option>
            <option value="ES">ES</option>
            <option value="FR">FR</option>
            <option value="AU">AU</option>
          </select>

          <input
            type="email"
            placeholder="Email"
            className="welcome-modal-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button className="welcome-modal-submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;