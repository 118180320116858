import React from 'react';
import { Link } from 'react-router-dom';
import './LoverSetTerritory.css';

const SushiMakerTerritory = () => {
  return (
    <div className="loverset-territory-page">
      <div className="loverset-product-image-container">
        {/* Replace with the actual path to your arranged product image */}
        <img 
          src="/aya_website_data/homepage_pics/lover_set_cropped.jpg"
          alt="Lover Set product arrangement"
          className="loverset-image"
        />
      </div>

      <h2 className="territory-text">Click On Your Territory</h2>

      <div className="flags-container">
        {/* Replace these routes and image paths with the correct ones for each territory */}
        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/us.jpg" 
            alt="US" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-it">
          <img 
            src="/aya_website_data/flag_pics/italy.jpg" 
            alt="Italy" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-de">
          <img 
            src="/aya_website_data/flag_pics/germany.jpg" 
            alt="Germany" 
            className="flag-img" 
          />
        </Link>        

        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/uk.jpg" 
            alt="UK" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-es">
          <img 
            src="/aya_website_data/flag_pics/spain.jpg" 
            alt="Spain" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-fr">
          <img 
            src="/aya_website_data/flag_pics/france.jpg" 
            alt="France" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/australia.jpg" 
            alt="Australia" 
            className="flag-img" 
          />
        </Link>
      </div>
    </div>
  );
};

export default SushiMakerTerritory;