import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-container">
        {/* Title */}
        <div className="privacy-policy-title">PRIVACY POLICY</div>

        {/* Content Section */}
        <div className="privacy-policy-content">
          {/* Left Column */}
          <div className="privacy-policy-left">
            <h3>WHAT THIS PRIVACY POLICY COVERS</h3>
            <p>
            This policy explains how Sushi AYA treats personal information and other data collected 
            and received through our website, including interactions with the Sushi AYA Sushi Chef 
            Assistant chatbot. Personal information includes your name, address, email address, 
            phone number, or other identifying details that are not publicly available.
            </p>
            <h3>INFORMATION COLLECTION AND USE</h3>
            <p>
            Sushi AYA collects personal information whenever a User contacts Sushi AYA through our website, 
            registers for a lifetime warranty of our products, or interacts with our Sushi Chef Assistant chatbot.             
            </p>
            <p>
            Sushi AYA uses personal information to notify you of your warranty registration and to provide updates, 
            special offers, or promotions related to Sushi AYA products. Sushi AYA also collects and stores 
            anonymized interaction data from the Sushi Chef Assistant chatbot to improve the chatbot’s performance, 
            and to enhance the overall customer experience. This data is stored securely and is never shared with third parties.
            </p>
          </div>

          {/* Right Column */}
          <div className="privacy-policy-right">
            <h3>INFORMATION SHARING AND DISCLOSURE</h3>
            <p>
            Sushi AYA does not rent, sell, or share personal information about you with other people or 
            non-affiliated companies, except to provide products or services you have requested or as required 
            by law or regulation. This data is never disclosed or shared with external entities.
            </p>
            <p>
              Sushi AYA will ensure that your personal data is not disclosed to
              state/provincial, national, regional institutions, and authorities
              unless required by law or other regulation.
            </p>
            <h3>DATA SECURITY AND TRANSPARENCY</h3>
            <p>
            Sushi AYA is committed to safeguarding your data. All personal and chatbot 
            interaction data is stored securely, and we continuously work to protect it 
            against unauthorized access, disclosure, or misuse.
            </p>

            <h3>CHANGES TO THIS PRIVACY POLICY</h3>
            <p>
            Sushi AYA may update this policy periodically. Any significant changes to how we treat 
            personal information or interaction data will be announced prominently on our website.
            </p>
          </div>
        </div>

        {/* Red Underline */}
        <div className="privacy-policy-underline" />
      </div>
    </div>
  );
};

export default PrivacyPolicy;