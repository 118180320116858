import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import WelcomeModal from '../components/WelcomeModal'; // Import the new component

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasShown = sessionStorage.getItem('hasShownWelcome');
    if (!hasShown) {
      // Show the modal only if we haven't shown it in this session yet
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    sessionStorage.setItem('hasShownWelcome', 'true');
  };

  return (
    <div className="homepage-wrapper">
      {/* If showModal is true, render the WelcomeModal */}
      {showModal && <WelcomeModal onClose={handleCloseModal} />}

      {/* Top section with the welcome message */}
      <div className="welcome-section">
        <p className="homepage-subtitle">SUSHI LOVERS, WELCOME!</p>
      </div>

      {/* Bottom section with icons */}
      <div className="icons-section">
        <div className="homepage-options">
          <Link to="/aya-assistant" className="option-link">
            <div className="option-box">
              <img 
                src="/aya_website_data/homepage_pics/aya_assistant.png" 
                alt="Aya Chatbot" 
                className="option-image"
              />
              <p className="option-text">TALK TO AYA 🍣</p>
            </div>
          </Link>

          <Link to="/choose-product" className="option-link">
            <div className="option-box">
              <img 
                src="/aya_website_data/homepage_pics/instructions.png" 
                alt="Instructions" 
                className="option-image" 
              />
              <p className="option-text">INSTRUCTIONS</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;