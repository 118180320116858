import React from 'react';
import './ChopsticksPage.css';

const ChopsticksPageEn = () => {
  return (
    <div className="chopsticks-page">
      <div className="chopsticks-content">
        <div className="chopsticks-image-section">
          <img 
            src="/aya_website_data/homepage_pics/chopsticks_product_page_cropped.jpg" 
            alt="Chopsticks Product"
            className="chopsticks-image"
          />
        </div>
        <div className="chopsticks-text-section">
          <h2 className="chopsticks-title">CHOPSTICKS</h2>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Premium and Safe Fiberglass Chopsticks.</span> AYA chopsticks are made of premium, highly durable and food grade material. Fiberglass chopsticks give you an incomparable eating experience, superior to wood, plastic or metal utensils.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Reusable Chopsticks.</span> AYA chopsticks are the perfect gift for sushi and Asian food lovers. Our chopsticks are dishwasher safe and designed to be very easily cleaned with no oil, grease or food residue.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Original Japanese Design.</span> The perfectly shaped AYA chopsticks are designed for easy handling, even for beginners. The easy-grip square design, and the pointy, non-slip, frosted tip guarantee an effortless eating experience.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Classic and Stylish.</span> AYA chopsticks are carved with our original AYA Japanese wave pattern, inspired by a Japanese classic design. AYA chopsticks are the perfect complement to your AYA sushi making kit.
          </p>

          <p className="chopsticks-paragraph">
            You are safe with us. AYA chopsticks come with a lifetime warranty.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChopsticksPageEn;