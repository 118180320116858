import React from "react";
import ProductSection from "../components/ProductSection";

const ChooseProductPage = () => {
  return (
    <div>      
      <ProductSection />
    </div>
  );
};

export default ChooseProductPage;