import React from 'react';
import './ChopsticksPage.css';

const ChopsticksPageEs = () => {
  return (
    <div className="chopsticks-page">
      <div className="chopsticks-content">
        <div className="chopsticks-image-section">
          <img 
            src="/aya_website_data/homepage_pics/chopsticks_product_page_cropped.jpg" 
            alt="Chopsticks Product"
            className="chopsticks-image"
          />
        </div>
        <div className="chopsticks-text-section">
          <h2 className="chopsticks-title">CHOPSTICKS</h2>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Palillos de fibra de vidrio seguros y de primera calidad.</span> Los palillos AYA están hechos de material de primera calidad, muy duradero y apto para uso alimentario. Los palillos de fibra de vidrio le brindan una experiencia gastronómica incomparable, superior a los utensilios de madera, plástico o metal.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Palillos reutilizables.</span> Los palillos AYA son el regalo perfecto para los amantes del sushi y la comida asiática. Nuestros palillos son aptos para lavavajillas y están diseñados para limpiarse muy fácilmente sin aceite, grasa o residuos de alimentos.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Diseño japonés original.</span> Los palillos AYA de forma perfecta están diseñados para un fácil manejo, incluso para principiantes. El diseño cuadrado de fácil agarre y la punta puntiaguda, antideslizante y esmerilada garantizan una experiencia de comer sin esfuerzo.
          </p>

          <p className="chopsticks-paragraph">
            <span className="highlighted-sentence">Clásico y elegante.</span> Los palillos AYA están tallados con nuestro patrón de ondas japonés AYA original, inspirado en un diseño clásico japonés. Los palillos AYA son el complemento perfecto para tu kit de preparación de sushi AYA.
          </p>

          <p className="chopsticks-paragraph">
            Estás a salvo con nosotros. Los palillos AYA vienen con una garantía de por vida.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChopsticksPageEs;