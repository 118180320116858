import React from "react";
import PrivacyPolicyContent from "../components/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPolicy;