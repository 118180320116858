import React, { useState } from "react";
import "./SupportPage.css";
import CONFIG from '../config';

const SupportPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    territory: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { BASE_URL } = CONFIG;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}send_support_email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your message has been sent successfully!");
        setFormData({ name: "", email: "", territory: "", subject: "", message: "" });
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      alert("Failed to send the message. Please try again.");
    }
  };

  return (
    <div className="support-page">
      <div className="support-half left">
        <div className="support-card support-card-left">
          <h2 className="support-card-title">For customer support and to activate your lifetime warranty, drop us a line!</h2>
          <form className="support-form" onSubmit={handleSubmit}>
            <label>
              Your Name (required)*
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </label>
            <label>
              Your Email (required)*
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label>
              Choose Your Territory
              <select name="territory" value={formData.territory} onChange={handleChange}>
                <option value="US">US</option>
                <option value="IT">IT</option>
                <option value="DE">DE</option>
                <option value="UK">UK</option>
                <option value="ES">ES</option>
                <option value="FR">FR</option>
                <option value="AU">AU</option>
              </select>
            </label>
            <label>
              Subject
              <input type="text" name="subject" value={formData.subject} onChange={handleChange} />
            </label>
            <label>
              Your Message
              <textarea name="message" rows="5" value={formData.message} onChange={handleChange} />
            </label>
            <button type="submit" className="submit-button">Send</button>
          </form>
        </div>
      </div>

      <div className="support-half right">
        <div className="support-card support-card-right">
          <h2 className="contact-us-text">CONTACT US</h2>
          <img src="/aya_website_data/homepage_pics/sushi_aya_logo.png" alt="Aya Logo" className="contact-logo" />
          <p>
            SushiAYA/ AYA® <strong>KROM LTD LLC</strong><br />
            477 Madison Ave, Suite 410, New York, NY 10022<br />
            United States (+1) 518 406 8992
          </p>
          <p>
            SushiAYA/ AYA® <strong>KROM LTD</strong><br />
            1st Floor, 244 Edgware Road, W2 1DS London<br />
            England (+44) 203 289 3523
          </p>
          <p>
            SushiAYA/ AYA® <strong>KROM LTD BV</strong><br />
            Herengracht 449A, Amsterdam, 1017 BR<br />
            The Netherlands
          </p>
          <hr className="contact-divider" />
        </div>
      </div>
    </div>
  );
};

export default SupportPage;