const productMedia = {
    "bazooka-kit-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "BAZOOKA FOR BEGINNERS",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bazooka kit 2 - Taking bazooka apart V2.mp4",
              title: "Taking Bazooka Apart",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bazooka kit 2 - Taking bazooka apart V2 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bazooka kit 2 - Making a roll V3.mp4",
              title: "Making a Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bazooka kit 2 - Making a roll V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "BAMBOO MAT FOR EXPERTS",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            },
          ],
        },
      ],
      instructions_section_title: "WRITTEN INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/bazooka_data/bazooka_instructions/en/bazooka.pdf",
          title: "Bazooka"
        },
        {
          id: 2,
          src: "aya_website_data/bazooka_data/bazooka_instructions/en/nigiri.pdf",
          title: "Nigiri"
        }
      ]
    },
    "sushimaker-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "AYA SUSHI MAKER",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Small Maki Sushi Maki Rolls V3.mp4",
              title: "Small Maki Sushi Rolls",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Small Maki Sushi Maki Rolls V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Round Sushi Rolls V3.mp4",
              title: "Round Sushi Rolls",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Round Sushi Rolls V3 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Heart Shaped Sushi Rolls V3.mp4",              
              title: "Heart Shaped Sushi Rolls",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Heart Shaped Sushi Rolls V3 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Triangle Sushi Rolls V3.mp4",              
              title: "Triangle Sushi Rolls",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Triangle Sushi Rolls V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "AYA BAMBOO MAT",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "WRITTEN INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/ENG_MBL_Round_Sushi.pdf",
          title: "Round Sushi"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/small_maki.pdf",
          title: "Small Maki"
        },
        {
          id: 3,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/heart_shaped_sushi.pdf",
          title: "Heart Shaped Sushi"
        },
        {
          id: 4,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/triangle_sushi.pdf",
          title: "Triangle Sushi"
        },
        {
          id: 5,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/nigiri.pdf",
          title: "Nigiri"
        },
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/salmon_maki.pdf",
          title: "Salmon Maki"
        }
      ]
    },
    "sushimaker-fr": {
      videos_section_title: "VIDÉOS D'INSTRUCTIONS",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Small maki sushi maki rolls V5 FR.mp4",
              title: "Petits Makis",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Small maki sushi maki rolls V5 FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Round sushi rolls V5 FR.mp4",
              title: "Rouleau de Sushi",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Round sushi rolls V5 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Heart shaped sushi rolls V5 FR.mp4",
              title: "Sushi en Forme de Coeur",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Heart shaped sushi rolls V5 FR thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Triangle sushi rolls V5 FR.mp4",
              title: "Sushi en Triangles",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Triangle sushi rolls V5 FR thumbnail.jpg"
            }
          ]
        },
        {
          title: "NATTE EN BAMBOO AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR.mp4",
              title: "Protégez la Natte de Bamboo",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR.mp4",
              title: "Maki au Saumon",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR.mp4",
              title: "Rouleau Californien",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR.mp4",
              title: "Rouleau Arc-en-Ciel",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR thumbnail.jpg"
            }
          ]
        },
      ],
      instructions_section_title: "PDF D'INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/rouleau_de_sushi.pdf",
          title: "Rouleau de Sushi"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/petits_makis.pdf",
          title: "Petits Makis"
        },
        {
          id: 3,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/sushi_en_forme_de_coeur.pdf",
          title: "Sushi en Forme de Coeur"
        },
        {
          id: 4,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/sushi_en_triangle.pdf",
          title: "Sushi en Triangle"
        },
        {
          id: 5,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/nigiri.pdf",
          title: "Nigiri"
        },
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/maki_au_saumon.pdf",
          title: "Maki au Saumon"
        }
      ]
    },
    "sushimaker-it": {
      videos_section_title: "VIDEO D’ISTRUZIONI",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Small maki sushi maki rolls V5 ITA.mp4",
              title: "Piccolo Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Small maki sushi maki rolls V5 ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Round sushi rolls V5 ITA.mp4",
              title: "Rotoli di Sushi Rotondi",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Round sushi rolls V5 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Heart shaped sushi rolls V5 ITA.mp4",
              title: "Sushi a Forma di Cuore",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Heart shaped sushi rolls V5 ITA thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Triangle sushi rolls V5 ITA.mp4",
              title: "Sushi a Forma di Triangolo",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Triangle sushi rolls V5 ITA thumbnail.jpg"
            }
          ]
        },
        {
          title: "STUOIA DI BAMBÚ AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA.mp4",
              title: "Avvolgete il Vostro Tappetino di Bambù nel Celophan",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA.mp4",
              title: "Salmone Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA.mp4",
              title: "Rotolo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA.mp4",
              title: "Rotolo di Arcobaleno",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF D’ISTRUZIONI",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/IT_MBL_Round_Sushi.pdf",
          title: "Rotoli di Sushi Rotondi"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/piccolo_maki.pdf",
          title: "Piccolo Maki"
        },
        {
          id: 3,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/sushi_a_forma_di_cuore.pdf",
          title: "Sushi a Forma di Cuore"
        },
        {
          id: 4,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/sushi_a_forma_di_triangolo.pdf",
          title: "Sushi a Forma di Triangolo"
        },
        {
          id: 5,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/nigiri.pdf",
          title: "Nigiri"
        },
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/salmone_maki.pdf",
          title: "Salmone Maki"
        }
      ]
    },
    "sushimaker-es": {
      videos_section_title: "TUTORIALES",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Small maki sushi maki rolls V5 SP.mp4",
              title: "Pequeño Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Small maki sushi maki rolls V5 SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Round sushi rolls V5 SP.mp4",
              title: "Rollo de Sushi",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Round sushi rolls V5 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Heart shaped sushi rolls V5 SP.mp4",
              title: "Sushi en Forma de Corazón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Heart shaped sushi rolls V5 SP thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Triangle sushi rolls V5 SP.mp4",
              title: "Sushi Triangular",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Triangle sushi rolls V5 SP thumbnail.jpg"
            }
          ]
        },
        {
          title: "ESTERILLA DE BAMBÚ AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP.mp4",
              title: "Envolviendo tu Estera de Bambú en Plástico",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP.mp4",
              title: "Maki de Salmón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP.mp4",
              title: "Rollo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP.mp4",
              title: "Rollo de Arco Iris",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF TUTORIALES",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_MBL_Round_Sushi.pdf",
          title: "Rollo de Sushi"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_MBL_Small_Maki.pdf",
          title: "Pequeño Maki"
        },
        {
          id: 3,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_MBL_Heart_Shaped_Sushi.pdf",
          title: "Sushi en Forma de Corazón"
        },
        {
          id: 4,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_MBL_Triangle_Sushi.pdf",
          title: "Sushi Triangular"
        },
        {
          id: 5,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/nigiri.pdf",
          title: "Nigiri"
        },
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_BKI_MBL_Salmon_Maki.pdf",
          title: "Maki De Salmón"
        }
      ]
    },
    "sushimaker-de": {
      videos_section_title: "LEHRVIDEOS",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Small maki sushi maki rolls V5 GER.mp4",
              title: "Kleiner Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Small maki sushi maki rolls V5 GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Round sushi rolls V5 GER.mp4",
              title: "Runde Sushi-Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Round sushi rolls V5 GER thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Heart shaped sushi rolls V5 GER.mp4",
              title: "Herzförmige Sushi-Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Heart shaped sushi rolls V5 GER thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Triangle sushi rolls V5 GER.mp4",
              title: "Dreieck-Sushi-Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Triangle sushi rolls V5 GER thumbnail.jpg"
            }
          ]
        },
        {
          title: "AYA BAMBUSMATTE",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER.mp4",
              title: "Die Bambusmatte in Plastik wickeln",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER.mp4",
              title: "Lachs Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER.mp4",
              title: "California Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER.mp4",
              title: "Regenbogenrolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF-ANWEISUNGEN",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_MBL_Round_Sushi.pdf",
          title: "Runde Sushi Rolle"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_MBL_Small_Maki.pdf",
          title: "Kleiner Maki"
        },
        {
          id: 3,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_MBL_Heart_Shaped_Sushi.pdf",
          title: "Herzförmige Sushi-Rolle"
        },
        {
          id: 4,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_MBL_Triangle_Sushi.pdf",
          title: "Dreieck Sushi Rolle"
        },
        {
          id: 5,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/nigiri_guide_MBL.pdf",
          title: "Nigiri"
        },
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_BKI_MBL_Salmon_Maki.pdf",
          title: "Lachs Maki"
        }
      ]
    },
    "loverset-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "AYA HEART SUSHI & NIGIRI MAKER",
          videos: [
            {
              id: 1,
              src: "aya_website_data/loverset_data/loverset_videos/en/What's in the box - Lover set V3.mp4",
              title: "What's in the Box",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/What's in the box - Lover set V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Heart Shaped Sushi Rolls V3.mp4",              
              title: "Heart Shaped Sushi Rolls",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/en/Sushi Maker - Heart Shaped Sushi Rolls V3 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "How To Use Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "AYA BAMBOO MAT",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            },
          ],
        },
      ],
      instructions_section_title: "WRITTEN INSTRUCTIONS",
      pdf_instructions: [        
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/heart_shaped_sushi.pdf",
          title: "Heart Shaped Sushi"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/salmon_maki.pdf",
          title: "Salmon Maki"
        }
      ]
    },
    "loverset-fr": {
      videos_section_title: "VIDÉOS D'INSTRUCTIONS",
      video_sections: [
        {
          title: "AYA COEUR SUSHI & NIGIRI",
          videos: [
            {
              id: 1,
              src: "aya_website_data/loverset_data/loverset_videos/fr/Lover Set - What's in the box FR.mp4",
              title: "Qu'y a-t-il dans la boite?",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/fr/Lover Set - What's in the box FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Heart shaped sushi rolls V5 FR.mp4",
              title: "Sushi en Forme de Coeur",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Sushi Maker - Heart shaped sushi rolls V5 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "NATTE EN BAMBOO AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR.mp4",
              title: "Protégez la Natte de Bamboo",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR.mp4",
              title: "Maki au Saumon",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR.mp4",
              title: "Rouleau Californien",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR.mp4",
              title: "Rouleau Arc-en-Ciel",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF D'INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/sushi_en_forme_de_coeur.pdf",
          title: "Sushi en Forme de Coeur"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/maki_au_saumon.pdf",
          title: "Maki au Saumon"
        }
      ]
    },
    "loverset-es": {
      videos_section_title: "TUTORIALES",
      video_sections: [
        {
          title: "AYA CORAZÓN SUSHI & NIGIRI",
          videos: [
            {
              id: 1,
              src: "aya_website_data/loverset_data/loverset_videos/sp/Lover Set - What's in the box SP.mp4",
              title: "¿Qué hay en la caja?",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/sp/Lover Set - What's in the box SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Heart shaped sushi rolls V5 SP.mp4",
              title: "Sushi en Forma de Corazón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Sushi Maker - Heart shaped sushi rolls V5 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ],
        },
        {
          title: "ESTERILLA DE BAMBÚ AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP.mp4",
              title: "Envolviendo tu Estera de Bambú en Plástico",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP.mp4",
              title: "Maki de Salmón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP.mp4",
              title: "Rollo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP.mp4",
              title: "Rollo de Arco Iris",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF TUTORIALES",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_MBL_Heart_Shaped_Sushi.pdf",
          title: "Sushi en Forma de Corazón"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_BKI_MBL_Salmon_Maki.pdf",
          title: "Maki de Salmón"
        }
      ]
    },
    "loverset-it": {
      videos_section_title: "VIDEO D’ISTRUZIONI",
      video_sections: [
        {
          title: "AYA CUORE SUSHI & NIGIRI",
          videos: [
            {
              id: 1,
              src: "aya_website_data/loverset_data/loverset_videos/it/Lover Set - What's in the box ITA.mp4",
              title: "Cosa c'è Nella Scatola?",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/it/Lover Set - What's in the box ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Heart shaped sushi rolls V5 ITA.mp4",
              title: "Sushi a Forma de Cuore",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Sushi Maker - Heart shaped sushi rolls V5 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "STUOIA DI BAMBÚ AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA.mp4",
              title: "Avvolgete il Vostro Tappetino di Bambù nel Celophan",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA.mp4",
              title: "Salmone Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA.mp4",
              title: "Rotolo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA.mp4",
              title: "Rotolo di Arcobaleno",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF D’ISTRUZIONI",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/sushi_a_forma_di_cuore.pdf",
          title: "Sushi a Forma di Cuore"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/salmone_maki.pdf",
          title: "Salmone Maki"
        }
      ]
    },
    "loverset-de": {
      videos_section_title: "LEHRVIDEOS",
      video_sections: [
        {
          title: "AYA HERZ SUSHI & NIGIRI",
          videos: [
            {
              id: 1,
              src: "aya_website_data/loverset_data/loverset_videos/ger/Lover Set - What's in the box GER.mp4",
              title: "Was Ist in Der Box?",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/ger/Lover Set - What's in the box GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Heart shaped sushi rolls V5 GER.mp4",
              title: "Herzförmige Sushi-Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Sushi Maker - Heart shaped sushi rolls V5 GER thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "AYA BAMBUSMATTE",
          videos: [
            {
              id: 1,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER.mp4",
              title: "Die Bambusmatte in Plastik wickeln",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER.mp4",
              title: "Lachs Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER.mp4",
              title: "California Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER.mp4",
              title: "Regenbogenrolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER thumbnail.jpg"
            },
          ],
        },
      ],
      instructions_section_title: "PDF-ANWEISUNGEN",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_MBL_Heart_Shaped_Sushi.pdf",
          title: "Herzförmige Sushi-Rolle"
        },
        {
          id: 2,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_BKI_MBL_Salmon_Maki.pdf",
          title: "Lachs Maki"
        }
      ]
    },
    "bambookit2-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bambookit2_data/bambookit2_videos/en/What's in the box - Bamboo kit 2 V3.mp4",
              title: "What's in the Box?",
              thumbnail: "aya_website_data/bambookit2_data/bambookit2_videos/en/What's in the box - Bamboo kit 2 V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            },
            {
              id: 6,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ],
        },
      ],
      instructions_section_title: "WRITTEN INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/en/salmon_maki.pdf",
          title: "Salmon Maki"
        }
      ]
    },
    "bambookit2-fr": {
      videos_section_title: "VIDÉOS D'INSTRUCTIONS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bambookit2_data/bambookit2_videos/fr/Bamboo Kit - What's in the box V4 FR.mp4",
              title: "Qu'y a-t-il dans la boite?",
              thumbnail: "aya_website_data/bambookit2_data/bambookit2_videos/fr/Bamboo Kit - What's in the box V4 FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR.mp4",
              title: "Protégez la Natte de Bamboo",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR.mp4",
              title: "Maki au Saumon",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR.mp4",
              title: "Rouleau Californien",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR.mp4",
              title: "Rouleau Arc-en-Ciel",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR thumbnail.jpg"
            },
            {
              id: 6,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ],
        },
      ],
      instructions_section_title: "PDF D’INSTRUCTIONS",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/fr/maki_au_saumon.pdf",
          title: "Maki au Saumon"
        }
      ]
    },
    "bambookit2-es": {
      videos_section_title: "TUTORIALES",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bambookit2_data/bambookit2_videos/sp/Bamboo Kit - What's in the box V4 SP.mp4",
              title: "¿Qué hay en la caja?",
              thumbnail: "aya_website_data/bambookit2_data/bambookit2_videos/sp/Bamboo Kit - What's in the box V4 SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP.mp4",
              title: "Envolviendo tu Estera de Bambú en Plástico",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP.mp4",
              title: "Maki de Salmón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP.mp4",
              title: "Rollo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP.mp4",
              title: "Rollo de Arco Iris",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP thumbnail.jpg"
            },
            {
              id: 6,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        }
      ],
      instructions_section_title: "PDF TUTORIALES",
      pdf_instructions: [
        {
          id: 1,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/sp/SP_BKI_MBL_Salmon_Maki.pdf",
          title: "Maki De Salmón"
        }
      ]
    },
    "bambookit2-it": {
      videos_section_title: "VIDEO D’ISTRUZIONI",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bambookit2_data/bambookit2_videos/it/Bamboo Kit - What's in the box V4 ITA.mp4",
              title: "¿Qué hay en la caja?",
              thumbnail: "aya_website_data/bambookit2_data/bambookit2_videos/it/Bamboo Kit - What's in the box V4 ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA.mp4",
              title: "Avvolgete il Vostro Tappetino di Bambù nel Celophan",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA.mp4",
              title: "Salmone Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA.mp4",
              title: "Rotolo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA.mp4",
              title: "Rotolo di Arcobaleno",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA thumbnail.jpg"
            },
            {
              id: 6,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ],
        },
      ],
      instructions_section_title: "PDF D’ISTRUZIONI",
      pdf_instructions: [
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/it/salmone_maki.pdf",
          title: "Salmone Maki"
        }
      ]
    },
    "bambookit2-de": {
      videos_section_title: "LEHRVIDEOS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bambookit2_data/bambookit2_videos/ger/Bamboo Kit - What's in the box V4 GER.mp4",
              title: "Was ist in der Box?",
              thumbnail: "aya_website_data/bambookit2_data/bambookit2_videos/ger/Bamboo Kit - What's in the box V4 GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER.mp4",
              title: "Die Bambusmatte in Plastik wickeln",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER thumbnail.jpg"
            },{
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER.mp4",
              title: "Lachs Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER.mp4",
              title: "California Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER.mp4",
              title: "Regenbogenrolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER thumbnail.jpg"
            },
            {
              id: 6,
              src: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3.mp4",              
              title: "Nigiri Maker",
              thumbnail: "aya_website_data/loverset_data/loverset_videos/en/Bazooka kit 2 - Nigiri Maker V3 thumbnail.jpg"
            }
          ],
        },
      ],
      instructions_section_title: "PDF-ANWEISUNGEN",
      pdf_instructions: [
        {
          id: 6,
          src: "aya_website_data/sushimaker_data/sushimaker_instructions/ger/GER_BKI_MBL_Salmon_Maki.pdf",
          title: "Lachs Maki"
        }
      ]
    },
    "ecokit-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokit_data/ecokit_videos/en/What's in the box - Eco kit V4.mp4",
              title: "What's in the Box?",
              thumbnail: "aya_website_data/ecokit_data/ecokit_videos/en/What's in the box - Eco kit V4 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            },
          ]
        }
      ]
    },
    "ecokit-fr": {
      videos_section_title: "VIDÉOS D'INSTRUCTIONS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokit_data/ecokit_videos/fr/Eco Kit - What's in the box V5 FR.mp4",
              title: "Qu'y a-t-il dans la boite?",
              thumbnail: "aya_website_data/ecokit_data/ecokit_videos/fr/Eco Kit - What's in the box V5 FR thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR.mp4",
              title: "Protégez la Natte de Bamboo",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit  - How to plastic wrap V4 FR thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR.mp4",
              title: "Maki au Saumon",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Salmon Maki V5 FR thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR.mp4",
              title: "Rouleau Californien",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo kit - California Roll V6 FR thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR.mp4",
              title: "Rouleau Arc-en-Ciel",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/fr/Bamboo Kit - Rainbow V6 FR thumbnail.jpg"
            }
          ]
        }
      ]
    },
    "ecokit-es": {
      videos_section_title: "TUTORIALES",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokit_data/ecokit_videos/sp/Eco Kit - What's in the box V5 SP.mp4",
              title: "¿Qué hay en la caja?",
              thumbnail: "aya_website_data/ecokit_data/ecokit_videos/sp/Eco Kit - What's in the box V5 SP thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP.mp4",
              title: "Envolviendo tu Estera de Bambú en Plástico",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit  - How to plastic wrap V4 SP thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP.mp4",
              title: "Maki de Salmón",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Salmon Maki V5 SP thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP.mp4",
              title: "Rollo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo kit - California Roll V5 SP thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP.mp4",
              title: "Rollo de Arco Iris",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/sp/Bamboo Kit - Rainbow V6 SP thumbnail.jpg"
            },
          ]
        }
      ]
    },
    "ecokit-it": {
      videos_section_title: "VIDEO D’ISTRUZIONI",
      video_sections: [
        {
          title: "SUSHI MAKER AYA",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokit_data/ecokit_videos/it/Eco Kit - What's in the box V5 ITA.mp4",
              title: "Cosa c'è Nella Scatola?",
              thumbnail: "aya_website_data/ecokit_data/ecokit_videos/it/Eco Kit - What's in the box V5 ITA thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA.mp4",
              title: "Avvolgete il Vostro Tappetino di Bambù nel Celophan",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit  - How to plastic wrap V4 ITA thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA.mp4",
              title: "Salmone Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Salmon Maki V5 ITA thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA.mp4",
              title: "Rotolo California",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo kit - California Roll V5 ITA thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA.mp4",
              title: "Rotolo di Arcobaleno",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/it/Bamboo Kit - Rainbow V6 ITA thumbnail.jpg"
            }
          ]
        }
      ]
    },
    "ecokit-de": {
      videos_section_title: "LEHRVIDEOS",
      video_sections: [
        {
          title: "",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokit_data/ecokit_videos/ger/Eco Kit - What's in the box V5 GER.mp4",
              title: "Was ist in der Box?",
              thumbnail: "aya_website_data/ecokit_data/ecokit_videos/ger/Eco Kit - What's in the box V5 GER thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER.mp4",
              title: "Die Bambusmatte in Plastik wickeln",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit  - How to plastic wrap V4 GER thumbnail.jpg"
            },{
              id: 3,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER.mp4",
              title: "Lachs Maki",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Salmon Maki V5 GER thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER.mp4",
              title: "California Rolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo kit - California Roll V5 GER thumbnail.jpg"
            },
            {
              id: 5,
              src: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER.mp4",
              title: "Regenbogenrolle",
              thumbnail: "aya_website_data/sushimaker_data/sushimaker_videos/ger/Bamboo Kit - Rainbow V6 GER thumbnail.jpg"
            },
          ]
        }
      ]
    },
    "ecokitmax-en": {
      videos_section_title: "VIDEO TUTORIALS",
      video_sections: [
        {
          title: "BAZOOKA AND NIGIRI FOR BEGINNERS",
          videos: [
            {
              id: 1,
              src: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/What's in the box - Eco kit max V4.mp4",
              title: "What's in the Box?",
              thumbnail: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/What's in the box - Eco kit max V4 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Taking Bazooka apart V3.mp4",
              title: "Round Sushi Rolls",
              thumbnail: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Taking Bazooka apart V3 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Making a Roll V3.mp4",              
              title: "Heart Shaped Sushi Rolls",
              thumbnail: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Making a Roll V3 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Nigiri Maker V3.mp4",              
              title: "Triangle Sushi Rolls",
              thumbnail: "aya_website_data/ecokitmax_data/ecokitmax_videos/en/Eco kit MAX - Nigiri Maker V3 thumbnail.jpg"
            }
          ]
        },
        {
          title: "BAMBOO MAT FOR EXPERTS",
          videos: [
            {
              id: 1,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3.mp4",
              title: "Wrap the Bamboo Mat in Plastic",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - How to plastic wrap V3 thumbnail.jpg"
            },
            {
              id: 2,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4.mp4",
              title: "Salmon Maki",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo Kit - Salmon maki roll V4 thumbnail.jpg"
            },
            {
              id: 3,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4.mp4",
              title: "California Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - California roll V4 thumbnail.jpg"
            },
            {
              id: 4,
              src: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4.mp4",
              title: "Rainbow Roll",
              thumbnail: "aya_website_data/bazooka_data/bazooka_videos/en/Bamboo kit - Rainbow Roll V4 thumbnail.jpg"
            }
          ]
        }
      ]
    },
  };
  
  export default productMedia;