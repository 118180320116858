import React from 'react';
import { Link } from 'react-router-dom';
import './BambooKit2Territory.css';

const SushiMakerTerritory = () => {
  return (
    <div className="bambookit2-territory-page">
      <div className="bambookit2-product-image-container">
        {/* Replace with the actual path to your arranged product image */}
        <img 
          src="/aya_website_data/homepage_pics/bamboo_kit_2_cropped.jpg"
          alt="Bamboo Kit 2 product arrangement"
          className="bambookit2-image"
        />
      </div>

      <h2 className="territory-text">Click On Your Territory</h2>

      <div className="flags-container">
        {/* Replace these routes and image paths with the correct ones for each territory */}
        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/us.jpg" 
            alt="US" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-it">
          <img 
            src="/aya_website_data/flag_pics/italy.jpg" 
            alt="Italy" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-de">
          <img 
            src="/aya_website_data/flag_pics/germany.jpg" 
            alt="Germany" 
            className="flag-img" 
          />
        </Link>        

        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/uk.jpg" 
            alt="UK" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-es">
          <img 
            src="/aya_website_data/flag_pics/spain.jpg" 
            alt="Spain" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-fr">
          <img 
            src="/aya_website_data/flag_pics/france.jpg" 
            alt="France" 
            className="flag-img" 
          />
        </Link>

        <Link to="/sushimaker-en">
          <img 
            src="/aya_website_data/flag_pics/australia.jpg" 
            alt="Australia" 
            className="flag-img" 
          />
        </Link>
      </div>
    </div>
  );
};

export default SushiMakerTerritory;