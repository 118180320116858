// ChatbotPage.jsx
import React, { useEffect, useState } from 'react';
import './ChatbotPage.css';
import Chatbox from '../components/Chatbox';
import { Link } from 'react-router-dom';

const ChatbotPage = () => {
  const [lastInstructionsPage, setLastInstructionsPage] = useState(null);

  useEffect(() => {
    // Check if user visited instructions page before
    const storedInstructionsPage = sessionStorage.getItem('lastInstructionsPage');
    setLastInstructionsPage(storedInstructionsPage);

    // Mark that user has talked to Aya (visiting chatbot page)
    sessionStorage.setItem('hasTalkedToAya', 'true');
  }, []);

  const instructionsText = lastInstructionsPage ? "Back to Instructions" : "Instructions";
  const instructionsLink = lastInstructionsPage ? `/${lastInstructionsPage}` : "/choose-product";

  return (
    // ChatbotPage.jsx
    <div className="chatbox-section">
      <div className="chatbox-container">
        <Chatbox />
        
        {/* Instructions Icon inside chatbox-container */}
        <Link to={instructionsLink} className="instructions-icon-link">
          <div className="instructions-icon-box">
            <img 
              src="/aya_website_data/homepage_pics/instructions_img.png"
              alt="Instructions"
              className="instructions-icon-image"
            />
            <p className="instructions-icon-text">{instructionsText}</p>
          </div>
        </Link>
        <div className="bot-image">
        <img 
          src="/aya_website_data/homepage_pics/aya_bot_transparent.png"
          alt="bot image"
          className="bot-image-img"
        />
      </div>
      </div>
    </div>
  );
};

export default ChatbotPage;